interface HelpNavbar {
  title: string;
}

export const helpPages = [
  {
    href: route("Faqs"),
    active: route().current("Faqs"),
    label: "FAQs",
  },
  {
    href: route("contact"),
    active: route().current("contact"),
    label: "Contact",
  },
  {
    href: route("contact"),
    active: route().current("contact"),
    label: "Shipping",
  },
  {
    href: route("contact"),
    active: route().current("contact"),
    label: "Returns",
  },
  {
    href: route("contact"),
    active: route().current("contact"),
    label: "Payment",
  },
  {
    href: route("contact"),
    active: route().current("contact"),
    label: "Promotions",
  },
  {
    href: route("contact"),
    active: route().current("contact"),
    label: "Account",
  },
];

function HelpNavbar({ title }: HelpNavbar) {
  return (
    <div className="container my-10 grid grid-cols-1 items-center gap-4 lg:grid-cols-12">
      <div className="col-span-3">
        <p className="text-[28px] font-[600] leading-[30.8px]">{title}</p>
      </div>
      <div className="col-span-9">
        <ul className="flex flex-wrap gap-4">
          {helpPages.map((item, index) => (
            <li key={index}>
              <a
                className={`text-[16px] font-[500] leading-[15px] hover:text-black ${item.active ? "text-black hover:text-fire-gray" : "text-fire-gray"} `}
                href={item.href}>
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default HelpNavbar;
